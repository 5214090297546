import { useState, useCallback } from "react";


// hook for form control and form validation
export function useFormWithValidation() {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [apiErr, setApiErr] = useState('');
    const [isValid, setIsValid] = useState(false);
  
    const handleChange = (event) => {
      setApiErr('');
      const target = event.target;
      const name = target.name;
      const value = target.value;
      setValues({...values, [name]: value});
      setErrors({...errors, [name]: target.validationMessage });
      setIsValid(target.closest("form").checkValidity());

    };
    const resetForm = useCallback(
      (newValues = {}, newErrors = {}, newIsValid = false) => {
        setValues(newValues);
        setErrors(newErrors);
        setIsValid(newIsValid);
      },
      [setValues, setErrors, setIsValid]
    );
  
    return { values, handleChange, errors, apiErr, setApiErr, isValid, resetForm };
  }